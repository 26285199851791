import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/styles.css";
import { useState } from "react";
import {
  Container,
  Row,
  Col,
  Carousel,
  Nav,
  Navbar,
  Image,
  Offcanvas,
  Card,
  Button,
} from "react-bootstrap";
import Logo from "./assets/logo.png";
import LogoTransparente from "./assets/logo-transparente.png"
import whatsapp from "./assets/whatsapp.png"
import BacharelTeologia from "./assets/BacharelTeologia.jpg"
import EspecializacaoTeologia from "./assets/EspecializaçãoTeologia.jpg"
import MedioTeologia from "./assets/MédioTeologia.jpg"
import PropagaAvancado from "./assets/PropagaAvançado.jpg"
import PropagaBasico from "./assets/PropagaBásico.jpg"

function App() {
  return (
    <>
      <Navbar
        sticky="top"
        className="navbar-expand-xxxl"
        collapseOnSelect  
        expand="xxxl"
      >
        <Container>
          <Navbar.Brand href="#root" className="text-white">
            <Image
              style={{
                width: "70px",
                marginRight: "10px",
              }}
              src={LogoTransparente}
            />
            SETEPEB
          </Navbar.Brand>
          <Navbar.Toggle className="bg-white"/>
          <Navbar.Collapse className="fw-bolder text-black">
            <Nav className="ml-auto bg-white" id="warpcollapse">
              <Nav.Link href="#root" className="text-black">Home</Nav.Link>
              <Nav.Link href="#cursos" className="text-black">Cursos</Nav.Link>
              <Nav.Link href="#setepeb" className="text-black">Sede</Nav.Link>
              <Nav.Link href="#sobre" className="text-black">Sobre</Nav.Link>
              <Nav.Link href="#contato" className="text-black">Contato</Nav.Link>
              <Nav.Link
                className="text-primary fw-bolder"
                href="http://52.205.173.108:8080/sais/"
                target="_blank"
              >
                Acesso
              </Nav.Link>
            </Nav>
          </Navbar.Collapse> 
        </Container>
      </Navbar>
      <Container>
      <p id="videotext">
        Seminário Teológico Pentecostal Do Brasil
      </p>
      </Container>
      <Container id="videocontainer">
      <video src="https://ia801205.us.archive.org/7/items/video-02-1/Video%2002%20%281%29.mp4"
       muted
       autoPlay
       loop>
       </video>
      </Container>
      <Container id="cursos">
        <Row>
          <Col className="p-5 pb-0 d-flex justify-content-center">
            <h1 className="jumbotron-heading">Nossos Cursos</h1>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col sm={12} md={4} className="p-4 d-flex justify-content-center">
            <Card style={{ width: "100%" }}>
              <Card.Img
                className="d-flex justify-content-center"
                style={{ width: "80%", margin: "0 auto" }}
                variant="top"
                src={MedioTeologia}
              />
              <Card.Body>
                <Card.Title>Curso médio em teologia </Card.Title>
                <Card.Text>
                Curso médio em teologia com 22 matérias na modalidade presencial, EAD ou extensão (implantação de turmas em igrejas locais) 

                </Card.Text>
                {/**<Button
                  style={{ border: "none", backgroundColor: "#ea5d2e" }}
                  variant="primary"
                >
                  Go somewhere
                </Button> */}
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12} md={4} className="p-4 d-flex justify-content-center">
            <Card style={{ width: "100%" }}>
              <Card.Img
                className="d-flex justify-content-center"
                style={{ width: "80%", margin: "0 auto" }}
                variant="top"
                src={BacharelTeologia}
              />
              <Card.Body>
                <Card.Title>Curso de Bacharel livre em teologia </Card.Title>
                <Card.Text>
                Curso de Bacharel livre em teologia com duração de 4 anos. Presencial EAD, e também 100% online.

                </Card.Text>
                {/**<Button
                  style={{ border: "none", backgroundColor: "#ea5d2e" }}
                  variant="primary"
                >
                  Go somewhere
                </Button> */}
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12} md={4} className="p-4 d-flex justify-content-center">
            <Card style={{ width: "100%" }}>
              <Card.Img
                className="d-flex justify-content-center"
                style={{ width: "80%", margin: "0 auto" }}
                variant="top"
                src={EspecializacaoTeologia}
              />
              <Card.Body>
                <Card.Title>Curso de especialização em teologia arminiana e pentecostal</Card.Title>
                <Card.Text>
                Curso de especialização em teologia arminiana e pentecostal 100% online ao vivo (duração de 12 meses)
                </Card.Text>
                {/**<Button
                  style={{ border: "none", backgroundColor: "#ea5d2e" }}
                  variant="primary"
                >
                  Go somewhere
                </Button> */}
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12} md={4} className="p-4 d-flex justify-content-center">
            <Card style={{ width: "100%" }}>
              <Card.Img
                className="d-flex justify-content-center"
                style={{ width: "80%", margin: "0 auto" }}
                variant="top"
                src={PropagaBasico}
              />
              <Card.Body>
                <Card.Title>Curso propaga básico</Card.Title>
                <Card.Text>
                Curso propaga básico 100% online (curso de 19 matérias para obreiros e pregadores iniciantes) 

                </Card.Text>
                {/**<Button
                  style={{ border: "none", backgroundColor: "#ea5d2e" }}
                  variant="primary"
                >
                  Go somewhere
                </Button> */}
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12} md={4} className="p-4 d-flex justify-content-center">
            <Card style={{ width: "100%" }}>
              <Card.Img
                className="d-flex justify-content-center"
                style={{ width: "80%", margin: "0 auto" }}
                variant="top"
                src={PropagaAvancado}
              />
              <Card.Body>
                <Card.Title>Curso propaga avançado </Card.Title>
                <Card.Text>
                Curso propaga avançado 100% online (22 matérias com conteúdo avançado para obreiros e pregadores cristãos em geral. (lançamento em 2024) 

                </Card.Text>
                {/**<Button
                  style={{ border: "none", backgroundColor: "#ea5d2e" }}
                  variant="primary"
                >
                  Go somewhere
                </Button> */}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container id="setepeb">
        <Row>
          <Col className="p-5 pb-0 d-flex justify-content-center">
            <h1 className="jumbotron-heading">O SETEPEB</h1>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col sm={12} md={4} className="p-4 d-flex justify-content-center">
            <figure className="figure">
              <img
                src={Logo}
                className="figure-img img-fluid rounded"
                alt="logo"
              />
              <figcaption className="figure-caption">
                A caption for the above image.
              </figcaption>
            </figure>
          </Col>
          <Col
            sm={12}
            md={8}
            className="p-4 d-flex justify-content-center flex-column"
          >
            <p>
            O SETEPEB SEMINÁRIO existe para formar obreiros nossa primeira missão é forjar profetas. Contamos com uma estrutura física espiritual e acadêmica perfeita para a fomentação do conhecimento teológico e do caráter cristão.  A maioria de nossos professores são pastores experientes e bem preparados. Nossa orientação teológica é pentecostal Arminiana, e nosso currículo é concentrado em exegese bíblica teologia pastoral e missionária.

            </p>
          </Col>
        </Row>
      </Container>
      <Container id="sobre">
        <Row>
          <Col className="p-5 pb-0 d-flex justify-content-center">
            <h1 className="jumbotron-heading">Quem Somos</h1>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col sm={12} md={4} className="p-4 d-flex justify-content-center">
            <Card
              className="text-center"
              style={{ width: "100%", border: "none" }}
            >
              <Card.Img
                className="d-flex justify-content-center"
                style={{ width: "80%", margin: "0 auto", borderRadius: "50%" }}
                variant="top"
                src={Logo}
              />
              <Card.Body>
                <Card.Title>Diretor - Jon Paul</Card.Title>
                <Card.Text>
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12} md={4} className="p-4 d-flex justify-content-center">
            <Card
              className="text-center"
              style={{ width: "100%", border: "none" }}
            >
              <Card.Img
                className="d-flex justify-content-center"
                style={{ width: "80%", margin: "0 auto", borderRadius: "50%" }}
                variant="top"
                src={Logo}
              />
              <Card.Body>
                <Card.Title>Diretor - Jon Paul</Card.Title>
                <Card.Text>
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12} md={4} className="p-4 d-flex justify-content-center">
            <Card
              className="text-center"
              style={{ width: "100%", border: "none" }}
            >
              <Card.Img
                className="d-flex justify-content-center"
                style={{ width: "80%", margin: "0 auto", borderRadius: "50%" }}
                variant="top"
                src={Logo}
              />
              <Card.Body>
                <Card.Title>Diretor - Jon Paul</Card.Title>
                <Card.Text>
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container
        style={{ backgroundColor: "#f1f3f4" }}
        className="p-0 m-0"
        fluid
      >
<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3690.185517415738!2d-46.921903300000004!3d-22.3466229!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c8568aae1edab1%3A0x889fc0fb2ab3d60d!2sR.%20H%C3%A9lcio%20Rodrigues%2C%20431%20-%20Jardim%20Novo%20II%2C%20Mogi%20Gua%C3%A7u%20-%20SP%2C%2013848-340!5e0!3m2!1spt-BR!2sbr!4v1701121233232!5m2!1spt-BR!2sbr"
 width="100%" 
 height="450"
 loading="lazy">
 </iframe>
      </Container>
      <footer id="contato">
        <div className="page-footer font-small blue p-5 m-5">
          <div className="container-fluid text-center text-md-left">
            <div className="row">
              <div className="col-md-6 mt-md-0 mt-3">
                <h5 className="text-uppercase">Contato</h5>
                <p>Telefone: (19)3361-1221 <br></br>
                Endereço: R. Hélcio Rodrigues, 431 - Jardim Novo II, Mogi Guaçu - SP <br></br>
                Cep: 13848-340
                </p>
              </div>

              <hr className="clearfix w-100 d-md-none pb-0" />

              <div className="col-md-3 mb-md-0 mb-3">
                <h5 className="text-uppercase">Telefones</h5>
                <ul className="list-unstyled">
                  <li>
                  EAD/ ESPECIALIZAÇÕES
                    <a href="https://wa.me/5519987351740">
                      <p><img src={whatsapp} alt="Logo do Whatsapp" 
                      className="d-inline justify-content-center"
                      style={{ width: "10%", marginRight: "2px"}}/>
                      (19) 98735-1740</p>
                    </a>
                  </li>
                  <li>
                  PRESENCIAL/ONLINE
                    <a href="https://wa.me/5519988346300">
                    <p><img src={whatsapp} alt="Logo do Whatsapp" 
                      className="d-inline justify-content-center"
                      style={{ width: "10%", marginRight: "2px"}}/>
                      (19) 98834-6300</p>
                    </a>
                  </li>
                  <li>
                  EXTENSÕES
                    <a href="https://wa.me/5519987724148">
                    <p><img src={whatsapp} alt="Logo do Whatsapp" 
                      className="d-inline justify-content-center"
                      style={{ width: "10%", marginRight: "2px"}}/>
                      (19) 98772-4148</p>
                    </a>
                  </li>
                </ul>
                </div>
                  <div className="col-md-3 mb-md-0 mb-3">
                  <h5 className="text-uppercase">Outros Links</h5>
                  <ul className="list-unstyled">
                    <li>
                      <a href="https://setepeb.bindemy.com.br">Plataforma On-line</a>
                    </li>
                  </ul>
                </div>
            </div>
          </div>
        </div>
        <Container fluid>
          <Row>
            <Col
              className="p-3 d-flex justify-content-center"
              style={{ backgroundColor: "#0098da", color: "#fff" }}
            >
              Copyrights © {new Date().getFullYear()} - Todos os direitos
              reservados
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default App;
